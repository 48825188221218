import React from 'react';
import "./styles.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import profileImage from './assets/profile.png';

function App() {
  return (
    <div className="container">
      <div className="container vh-100">

        <div className="row align-items-center h-100">
          
              <div className="col-md-6">
                <div className="text-left">
                  <h1 className="font-weight-bolder text-wrap" style={{width: "20rem"}}>Hi there, I'm Abraao</h1>
                  <p className="w-100 text-white text-break">In love with technology and mobile world. Senior iOS Developer, Web Dev and more...</p>
                  <button type="button" class="btn btn-light" onClick={ () => {
                    window.open("https://www.linkedin.com/in/abraaoan/", "_blank");
                  }}>Let's talk</button>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img className="img-fluid" style={{width: '80%'}} src={profileImage} alt="" />
              </div>
          
        </div>

        <div className="row justify-content-end">
          <h4 className="font-weight-bold" style={{fontSize: 14}}>Now with Bootstrap+ReactJS+GitHooks <span role="img" aria-label="rock">🤘🏼</span></h4>
        </div>

      </div>

      <div className="container vh-100">
      <div className="row w-100 h-75 justify-content-md-center">
          <div className="w-35 text-left align-self-center">
            <h2>Thank you <span role="img" aria-label="smiling">😊</span></h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
